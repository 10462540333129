.structure-selection-menu .bp3-popover-content {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hide;
    padding: 0px !important;
}

/* https://github.com/palantir/blueprint/issues/2094 */

.structure-selection-popover .bp3-popover {
    width: 90%;
}

.structure-selection-popover .bp3-transition-container {
    width: 90%;
}
