/* 
    Animated glow:
    https://stackoverflow.com/questions/34821217/easily-create-an-animated-glow 
*/
/*
@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #aef4af;
  }
  to {
    box-shadow: 0 0 10px 10px #aef4af;
  }
}
*/

/*@keyframes glow {
  from {
    filter: drop-shadow( 0 5px 5px gray )
  }
  to {
    filter: drop-shadow( 0 5px 5px red )
  }
}
*/

/*
@keyframes neon4 {
    from {
        filter: drop-shadow(0 0 10px #fff)drop-shadow( 0 0 20px #fff)drop-shadow( 0 0 30px #fff)drop-shadow( 0 0 40px rgb(252, 226, 32))drop-shadow( 0 0 70px rgb(252, 226, 32))drop-shadow( 0 0 80px rgb(252, 226, 32))drop-shadow( 0 0 100px rgb(252, 226, 32))drop-shadow( 0 0 150px rgb(252, 226, 32));;
    }
    to {
        filter: drop-shadow(0 0 5px #fff)drop-shadow( 0 0 10px #fff)drop-shadow( 0 0 15px #fff)drop-shadow( 0 0 20px rgb(252, 226, 32))drop-shadow( 0 0 35px rgb(252, 226, 32))drop-shadow( 0 0 40px rgb(252, 226, 32))drop-shadow( 0 0 50px rgb(252, 226, 32))drop-shadow( 0 0 75px rgb(252, 226, 32));
    }
  }
  */

/* color: blueprint blue5*/
@keyframes glow {
  from {
    filter: drop-shadow(0 0 10px #0E5A8A)
  }
  to {
    filter: drop-shadow(0 0 -10px #ffffff)
  }
}
