/*
CSS overrides to make NGLComponent like needed for server
*/

/* div.ui.menu {
    display: none;
}

div.ui.grid {
    display: none;
} */

/* hide top menu bar (does not have class so cannot select directly) */
.ui.card > div:nth-child(1) {
    height: 0px;
    display: none;
}

/* 
    Actual viewer panel;
    need to have 99% or panel content starts "drifting"
 */
.ui.card > div:nth-child(2) {
    width: 100% !important;
    height: 99% !important;
}

/*
    https://stackoverflow.com/questions/45737585/chrome-and-safari-flex-height-100-failure

    width 100% is probably not the right solution but works well anyways
    min-height so does downsize as outside flexbox gets smaller
*/
div.ui.card {
    display: flex !important;
    flex: 1;
    min-height: 0px !important;
    margin: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    width: 100% !important;
}
/*
    align-items: stretch !important;
    height: auto;
    width: auto;
*/


/* hide bottom menu bar (does not have class so cannot select directly) */
.ui.card > div:nth-child(3) {
    height: 0px;
    display: none;
}
